
<template>
  <TemplateSinglePage class="flex min-h-screen font-sans">
    <div id="ff-compose" />
  </TemplateSinglePage>
</template>

<script>
import tailwindConfig from '@/assets/css/tailwind.config.js';
import TemplateSinglePage from '@/components/TemplateSinglePage.vue';
export default {
  components: {
    TemplateSinglePage
  },
  data () {
    return {
      baseUrl: process.env.VUE_APP_BASE_URL,
      observer: null
    }
  },
  metaInfo () {
    return {
      title: 'Contact Us',
      meta: [
        { property: 'og:title', vmid: 'og:title', content: 'Contact Us' },
        { property: 'og:url', vmid: 'og:url', content: `${this.baseUrl}${this.$route.path}` }
      ]
    }
  },
  mounted () {
    const script = document.createElement('script')
    script.id = 'ff-script'
    script.src = 'https://formfacade.com/include/110015592378457870418/form/1FAIpQLSeV2gH09l_V1ABeJnRQgY-6_6nRtNFEuqGjSlsL7F6XNWzAaQ/classic.js?div=ff-compose'
    script.async = true
    script.defer = true
    document.body.appendChild(script)
    this.observer = new MutationObserver((mutations) => {
      mutations.forEach(async (mutation) => {
        if (mutation.type === 'childList') {
          // Hide brand image
          const ffPoweredImg = document.querySelector('.ff-powered-img')
          if (ffPoweredImg) {
            ffPoweredImg.style.display = 'none'
          }
          // Override styling
          const colors = tailwindConfig.theme.extend.colors
          if (colors) {
            const overrideStyles = (property, value) => document.querySelector(':root').style.setProperty(property, value)
            overrideStyles('--ff-primary-color', colors?.primary)
            overrideStyles('--ff-primary-light', colors?.primary)
            overrideStyles('--ff-font-color', 'white')
            overrideStyles('--ff-bgcolor', colors['bg-300'])
            overrideStyles('--ff-field-bgcolor', colors['bg-200'])
          }
          // Set build string
          const ffBuildString = document.querySelector('.ff-item:last-of-type textarea')
          if (ffBuildString) {
            ffBuildString.value = `wow-classic-db:${this.$store.state.name}:${this.$store.getters.buildString}`
          }
        }
      })
    })
    this.observer.observe(document.querySelector('#ff-compose'), { childList: true, subtree: true })
  },
  beforeDestroy () {
    this.observer.disconnect()
  }
}
</script>

