module.exports = {
  mode: 'jit',
  corePlugins: {
    brightness: true
  },
  purge: {
    content: ['../../lib/src/**/*.{js,jsx,ts,tsx,vue}', './src/**/*.{js,jsx,ts,tsx,vue}'],
    safelist: ['text-item-legendary']
  },
  darkMode: false, // or 'media' or 'class'
  theme: {
    fontFamily: {
      sans: ['Open Sans', 'ui-sans-serif', 'system-ui'],
      mono: ['ui-monospace', 'SFMono-Regular']
    },
    extend: {
      colors: {
        'bg-100': '#08260f',
        'bg-200': '#092610',
        'bg-300': '#0a210f',
        'bg-400': '#081a0c',
        'bg-500': '#07140a',
        'bg-600': '#09170c',
        'bg-700': '#0f1f12',
        'bg-800': '#09140b',
        'bg-900': '#0d1c10',
        'tooltip-border': '#4c4a49',
        'table-text': '#625f5d',
        'table-text-light': '#9F9D96',
        'table-1': '#1c231d',
        'table-2': '#1d231e',
        'table-3': '#242824',
        'table-4': '#202520',
        // Deprecated - old UI theme
        'bg-lighter': '#1c1c26',
        'bg-light': '#16161f',
        'bg-dark': '#1b1a1b',
        // End deprecation
        'footer-text': '#939089',
        primary: '#1fa644',
        tooltip: '#43434e',
        'item-poor': '#9d9d9d',
        'item-common': '#ffffff',
        'item-uncommon': '#1eff00',
        'item-rare': '#0070dd',
        'item-epic': '#a335ee',
        'item-legendary': '#ff8000',
        'item-artifact': '#e6cc80',
        'difficulty-very-easy': '#808080',
        'difficulty-easy': '#40BF40',
        'difficulty-normal': '#FFFF00',
        'difficulty-hard': '#FF8040',
        'difficulty-very-hard': '#FF4040',
        'tooltip-misc': '#ffd100',
        'button-active': '#0a210f',
        'button-inactive': '#07140a',
        warrior: '#c69b6d',
        paladin: '#F58CBA',
        hunter: '#ABD473',
        rogue: '#FFF569',
        priest: '#FFFFFF',
        shaman: '#0070DE',
        mage: '#69CCF0',
        warlock: '#9482C9',
        druid: '#FF7D0A'
      }
    }
  },
  variants: {
    extend: {
      display: ['group-hover', 'first'],
      backgroundColor: ['even']
    }
  },
  plugins: [
    require('@tailwindcss/line-clamp'),
    require('@tailwindcss/aspect-ratio'),
    require('@tailwindcss/forms'),
    require('@tailwindcss/typography')
  ]
}
